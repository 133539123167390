'use client';

import { Center, Stack, Text } from '@mantine/core';

import Button from '@/components/Buttons/Button';
import Image from 'next/image';
import Link from 'next/link';
import { images } from '@/utils/images';
import themeVariables from '@/styles/themeVariables';

export default function NotFound() {
  return (
    <Stack align={'center'}>
      <Image
        width={400}
        height={400}
        src={images.notFoundGif}
        alt="not-found"
        quality={100}
        priority
        style={{ borderRadius: '0.5rem' }}
      />
      <Text
        size={18}
        weight={700}
        mb={12}
        color={themeVariables.fontColors.medium}
      >
        해당 페이지가 존재하지 않습니다
      </Text>
      <Link href="/">
        <Center>
          <Button>메인으로 이동</Button>
        </Center>
      </Link>
    </Stack>
  );
}
